import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { token } from '@atlaskit/tokens';

import { isEmbeddedConfluence_DO_NOT_USE } from '@atlassian/embedded-confluence/isEmbeddedConfluence';
import { fg } from '@confluence/feature-gating';

// Both default styles are needed to properly apply `background-color` in resulting DOM when merged with look-and-feel
const DEFAULT_CONTAINER_STYLES = {
	background: token('elevation.surface'),
	backgroundColor: token('elevation.surface'),
};

const LOADING_SCREEN_STYLES = {
	position: 'fixed',
	width: '100%',
	height: '100%',
	top: '0',
	display: 'none',
	bottom: '0',
	left: '0',
	right: '0',
	zIndex: '10',
	background: token('color.skeleton'),
};

const _convertVerticalGutterSize = (gutterValue) => {
	switch (gutterValue) {
		case 'small':
		case 'medium':
		case 'large':
		case 'default':
			return null;
		default:
			return gutterValue;
	}
};

const _convertHorizontalGutterSize = (gutterValue) => {
	switch (gutterValue) {
		case 'small':
			return 5;
		case 'medium':
			return 10;
		case 'large':
			return 15;
		case 'default':
			return null;
		case 'none':
		default:
			return 0;
	}
};

export class ContentContainerComponent extends Component {
	static _getContainerStyles(
		lookAndFeel,
		isThemed,
		isLivePage,
		additionalPaddingTop,
		isObjectSidebarEnabled,
	) {
		if (!lookAndFeel.content) return DEFAULT_CONTAINER_STYLES;

		const { screen, container } = lookAndFeel.content;
		const containerStyles = { ...DEFAULT_CONTAINER_STYLES, ...container };

		const { padding } = containerStyles;

		let paddingTop = additionalPaddingTop || 0;

		// if page header is fixed, content will need to be pushed down so that the title of the page isn't covered (unless rendered via embedded-confluence)
		// themed spaces do not have fixed headers and do not need the styling
		if (!isThemed && !isLivePage && !isEmbeddedConfluence_DO_NOT_USE()) {
			if (fg('confluence_frontend_object_header')) {
				// if confluence_frontend_object_header is on, add 46px padding since the object header height is 56px and with-sticky-header div above it has 10px height already
				paddingTop += 46;
			} else {
				// add 60px padding since regular page header height is 60px.
				paddingTop += 60;
			}
		}

		containerStyles.padding = `${paddingTop}px 0px 0px 0px`;

		if (isThemed && padding === '0') {
			// if the theme doesn't have a padding, we should add a padding because otherwise comments overflow the container
			// see also: https://product-fabric.atlassian.net/wiki/spaces/~speachey/pages/3093430887/DEVHELP-6933
			containerStyles.padding = '20px';
		}

		const { gutterTop, gutterBottom } = screen;

		if (gutterTop) {
			containerStyles.marginTop = _convertVerticalGutterSize(screen.gutterTop);
		}

		if (gutterBottom) {
			containerStyles.marginBottom = _convertVerticalGutterSize(screen.gutterBottom);
		}

		const gutterRight = _convertHorizontalGutterSize(screen.gutterRight);
		const gutterLeft = _convertHorizontalGutterSize(screen.gutterLeft);

		if (gutterRight !== null) {
			containerStyles.marginRight = `${gutterRight}%`;
		}

		if (gutterLeft !== null) {
			containerStyles.marginLeft = `${gutterLeft}%`;
		}

		containerStyles.width = `${100 - gutterRight - gutterLeft}%`;
		containerStyles.boxSizing = 'border-box';

		if (isObjectSidebarEnabled) {
			containerStyles.display = 'flex';
			containerStyles.flexDirection = 'row';
		}

		return containerStyles;
	}

	static _getInlineCommentStyles(lookAndFeel) {
		if (!lookAndFeel.content) {
			return null;
		}

		const { screen = {} } = lookAndFeel.content;
		const gutterRight = _convertHorizontalGutterSize(screen.gutterRight);

		if (gutterRight === 0) {
			/**
			 * When we have no gutterRight, we need to override the default ic-sidebar marging-right of -20px and make it 0
			 *  so that it does not pull too far right.
			 */
			return (
                // eslint-disable-next-line @atlaskit/ui-styling-standard/no-global-styles -- Ignored via go/DSP-18766
                <style>{'.ic-sidebar.ic-is-spa-view-page { margin-right: 0 !important; }'}</style>
            );
		}

		return null;
	}

	getLoadingStyles() {
		const { isLoading } = this.props;
		return isLoading ? LOADING_SCREEN_STYLES : {};
	}

	render() {
		const {
			lookAndFeel,
			isThemed,
			isContentView,
			isLivePage,
			isObjectSidebarEnabled,
			additionalPaddingTop,
		} = this.props;
		const shouldApplyTheme = isThemed || isContentView;
		const containerStyles = shouldApplyTheme
			? ContentContainerComponent._getContainerStyles(
					lookAndFeel,
					isThemed,
					isLivePage,
					additionalPaddingTop,
					isObjectSidebarEnabled,
				)
			: this.getLoadingStyles();
		const inLineCommentStyles = shouldApplyTheme
			? ContentContainerComponent._getInlineCommentStyles(lookAndFeel)
			: null;
		return (
			<div
				data-testid="content-container-component"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={this.props.className}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
				style={containerStyles}
			>
				{inLineCommentStyles}
				{this.props.children}
			</div>
		);
	}
}

ContentContainerComponent.propTypes = {
	children: PropTypes.any,
	lookAndFeel: PropTypes.object,
	className: PropTypes.string,
	isThemed: PropTypes.bool,
	isLoading: PropTypes.bool,
	/**
	 * In view page we apply default style regardless whether theme was enabled or not
	 */
	isContentView: PropTypes.bool,
	isLivePage: PropTypes.bool,
	isObjectSidebarEnabled: PropTypes.bool,
	additionalPaddingTop: PropTypes.number,
};

ContentContainerComponent.defaultProps = {
	isContentView: false,
	lookAndFeel: {
		content: {
			screen: DEFAULT_CONTAINER_STYLES,
		},
	},
	isLivePage: false,
	isObjectSidebarEnabled: false,
};
